
div.loading-indicator {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    img {
        margin:auto;
        animation: shake .5s infinite;
        animation-direction: alternate;
    }
    ion-text {
        margin-top: 1ex;
        text-align: center;
    }
    @keyframes shake {
        0% {
            transform: rotate(-15deg);
        }
        50% {
            transform: rotate(15deg);
        }
        100% {
            transform: rotate(-15deg);
        }
    }
}
