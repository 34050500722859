
ion-list.factscard {
    width: -webkit-fill-available;

    ion-item {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        ion-text {
            overflow: hidden;
            text-overflow: ellipsis;
            flex: 1 0 0;
        }

        div {
            flex: 1 0 0;

            ion-icon {
                margin-right: 0.5ex;
                font-size: x-large;
            }
        }
    }
}

ion-text.fc-description {
    margin-right: 5px;
}

div.fc-scorechange {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    visibility: hidden;

    ion-text {
        text-align: center;
    }

    div.fc-scorechange-arrow {
        display: flex;
        margin-top: -40px;
        margin-bottom: -40px;

        span {
            height: 100px;
            width: 10px;
            margin: 27px;
            border-radius: 5px;
        }
    }
}
