
ion-list.cc-list {
    flex: 1 1 0;
}

ion-item.cc-comment div {
    padding-inline-end: 0;
}

ion-item.cc-comment ion-col {
    display: flex;
    flex-direction: column;
}

ion-row.cc-meta {
    font-size: small;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

ion-row.cc-meta ion-text {
    flex: 1 1 0;
    min-width: fit-content;
}

ion-text.cc-meta-name {
    margin-right: 5px;
    text-align: start;
}

ion-text.cc-meta-timestamp {
    text-align: end;
}

ion-item.cc-input {

    ion-button {
        margin: 0;
        height: 100%;
    }

    div.formcontainer {
        height: -webkit-fill-available
    }
}
