
ion-card.pm-element {
    min-width: 200px;
    flex: 1 1 0px;
}

ion-card-title.pm-element {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

ion-card-subtitle.pm-element {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

ion-row.pm-element {
    flex-flow: row;
    overflow: hidden;
}

div.pme-icon {
    display: flex;
    margin: 1.5px;
}

ion-icon.pm-element {
    font-size: large;
}

ion-label.pm-element {
    line-height: normal;
    align-self: center;
    white-space: nowrap;
}
