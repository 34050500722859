
div.selection {
    display: flex;
    width: -webkit-fill-available;
    justify-content: space-between;
}

ion-text.selection {
    padding-top: 1ex;
    padding-bottom: 1ex;
}

div.header {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    background-color: var(--ion-color-background, #FFFFFF);
}

ion-footer {
    display: flex;
    flex-direction: row;
    align-items: center;

    ion-item {
        flex: 1 1 0;
        align-items: center;

        ion-label {
            text-align: center;
        }
    }
    ion-button {
        flex: 0.5 1 0;
    }
}
