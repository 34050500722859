
div.imageslide {
    position: relative;

    img {
        width: 100%;
    }

    div.imageslide-text {
        position: absolute;
        bottom: 0.8ex;
        background-color: black;
        opacity: 0.5;
        color: white;

        div.imageslide-text-entry {
            display: flex;
            margin: 1.5px;

            ion-label {
                margin-left: 0.5ex;
            }
        }
    }
}

