
div.hso-item {
    display: flex;

    ion-item {
        width: 100%;
    }

    p {
        color: var(--ion-color-medium, #000000);
    }
}

div.hso-childcontainer {
    margin-left: 1.5ex;
}

div.hso-child {
    display: flex;
    flex-direction: column;
}
