
ion-card-content {
    display: flex;
    flex-direction: row;
}

div.tc-modify {
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
}

div.tc-modify ion-button {
        flex: 1 0 0;
}

div.tc-delete {
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    justify-content: flex-end;
}
