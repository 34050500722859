
div.formcontainer {
    margin-top: 1.25ex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

div.formfield-div {
    text-align: center;
    flex: 1 1 0;
}

div.formfield-div ion-item {
    width: 100%;
}

ion-button.createbutton {
    flex: 1 1 0;
    margin-bottom: 5ex;
    align-self: flex-end;
}

ion-input {
    flex: 1 1 0;
    width: 100%;
}

ion-item div {
    display: flex;
    align-items: center;
    width: 100%;
}

ion-text.formfield-prefix {
    margin-right: 1ex;
}

ion-text.formfield-suffix {
    margin-left: 1ex;
}

ion-select {
    padding-left: 0;
    padding-right: 0;
    min-width: 100%;
}
